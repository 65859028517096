<template>
  <div class="builder-container">
    <div class="preview-container">
      <step-title :step="step"><b>{{ page.korName }}</b> 페이지를 원하는 디자인의 <b>블럭 템플릿</b>으로 교체해보세요.</step-title>

      <!-- 목업 -->
      <div class="mockup-wrapper" @click="clear">
        <div :class="`${device}-mockup`" @click.stop.prevent>
        <div class="inner" style="overflow: hidden;padding-bottom:8px">
          <!-- Header -->
          <img :src="headerImg">
          <img style="margin-top:-8px" v-if="device==='mobile'"
               src="https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockGnb.png">
          <div v-for="(block,idx) in computedPageBlocks" :key="'page-block-'+idx" class="unselect position-relative"
               @mouseover="hover(block)"
               @mouseleave="block.hover=false">
            <div class="selector" v-if="selectedBlock.id === block.id">
              <div class="selector-name">{{ block.kor_name }}</div>
            </div>
            <img :src="block.preview" class="block-preview unselect"
                 :class="{'selected': block.idx === selectedBlock.idx}"
                 @click.stop.prevent="clickPageBlock(block)">
            <div v-if="block.hover && selectedBlock.id !== block.id" class="dim" @click.stop.prevent="clickPageBlock(block)">
              <div class="dim-content">
                <div class="h7">{{ block.kor_name }}</div>
                <div class="dim-circle">
                  <img src="/static/img/ic_vector.svg">
                </div>
              </div>

            </div>
          </div>
          <!-- Footer -->
          <img :src="footerImg">
        </div>
      </div>
      </div>
    </div>

    <!-- 속성 -->
    <div class="prop-container">
      <div style="padding:24px 20px">
        <div class="flex-between">
          <button class="button" :class="{'is-dark': device==='mobile', 'is-gray': device==='pc'}"
                  @click="device='mobile'">
            <img :class="{'selected-img': device==='mobile'}" src="static/img/ic_device_mobile.svg">모바일 버전
          </button>
          <button class="button" :class="{'is-dark': device==='pc', 'is-gray': device==='mobile'}" @click="device='pc'">
            <img :class="{'selected-img': device==='pc'}" src="static/img/ic_device_pc.svg">데스크탑 버전
          </button>
        </div>
        <div class="subtitle5 sub" style="margin-top:24px" v-if="blockName!==''">{{ blockName }} 템플릿</div>
      </div>
      <div class="builder-box position-relative" v-if="device==='pc'">
        <template v-for="block in blocks">
          <img :src="block.preview"
               class="block-template-preview unselect" :key="'block-'+block.id"
               :class="{'block-template-preview-selected':block.id === selectedBlock.id}"
               @click="clickBlock(block)">
        </template>
      </div>

      <div class="builder-box-mobile-wrapper">
        <div class="builder-box-mobile position-relative">
          <div v-if="!vBoxMobile" class="box-hidden"></div>
          <div v-for="block in blocks" :key="'block-'+block.id" class="block-template-mobile">
            <img :src="block.preview"
                 class="block-template-preview-mobile unselect"
                 :class="{'block-template-preview-selected':block.id === selectedBlock.id}"
                 @click="clickBlock(block)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StepTitle from "../StepTitle";
  import UserAPIMixin from "../../../../mixins/UserAPIMixin";

  export default {
    name: "BlockBuilder",
    components: {StepTitle},
    mixins: [
      UserAPIMixin
    ],
    props: {
      step: {
        type: Number
      },
      service: {
        type: Object
      }
    },
    created() {
      this.init();
      this.getData();
    },
    data() {
      return {
        vBoxMobile: false,
        device: 'mobile',
        page: undefined,
        pages: [
          {
            step: 3,
            korName: '홈 화면',
            name: "Home"
          },
          {
            step: 4,
            korName: '상품목록',
            name: "Product"
          },
          {
            step: 5,
            korName: '상품상세',
            name: "ProductDetail"
          },
        ],
        id: 1,
        blocks: [],
        selectedBlock: {id: 0},
        url: '',
        blockName: '',
        // 선택되면 안되는 블럭
        excludeBlock: ["BlockProductDetailBottomBtn"]
      }
    },
    methods: {
      init() {
        let page = this.pages.filter(page => {
          return page.step === this.step
        })[0];
        let servicePage = this.service.pages.filter(item => {
          return item.name === page.name
        });
        if (servicePage.length === 0) {
          this.page = {
            name: page.name,
            korName: page.korName,
            pageBlocks: [],
            pageBlocksPc: []
          };
          this.service.pages.push(this.page);

        } else {
          this.page = servicePage[0];
        }
      },
      getData() {
        if ((this.device === 'pc' && this.page.pageBlocksPc.length > 0) ||
          (this.device === 'mobile' && this.page.pageBlocks.length > 0)) {

          if (document.getElementsByClassName('inner').length > 0) {
            document.getElementsByClassName('inner')[0].scrollTop = 0;
          }

          // 첫번째 블럭 선택
          this.clickPageBlock(this.computedPageBlocks[0]);

          return;
        }
        let name = this.page.name;
        this.request.user.get(`ui/super_builder/page_blocks/${name}?service_key=${this.service.key}`).then(res => {
          this.page.pageBlocks = res.data.mobile.filter(item => {
            item.hover = false;
            return ['BlockGnb'].indexOf(item.name) === -1;
          });
          for (let i = 0; i < this.page.pageBlocks.length; i++) {
            this.page.pageBlocks[i].idx = i;
          }

          this.page.pageBlocksPc = res.data.pc.map(i=> { i.hover =false; return i; });
          for (let i = 0; i < this.page.pageBlocksPc.length; i++) {
            this.page.pageBlocksPc[i].idx = i;
          }

          this.$nextTick(() => {
            if (document.getElementsByClassName('inner').length > 0)
              document.getElementsByClassName('inner')[0].scrollTop = 0;

            // 첫번째 블럭 선택
            this.clickPageBlock(this.computedPageBlocks[0]);
          });
        });
      },
      getBlocks() {
        this.url = `ui/super_builder/blocks/${this.selectedBlock.name}?page_length=100`;
        let conv = this.service.conversions.map(item => {
          return item.params.filter(param => {
            return param.name === 'key'
          })[0].value
        }).toString();
        let plugin = this.service.plugins.map(item => {
          return item.params.filter(param => {
            return param.name === 'key'
          })[0].value
        }).toString();
        this.url += `&conversion=${conv}&plugin=${plugin}`;
        this.request.user.get(this.url).then(res => {

          // 현재 선택한 블럭 외, 다른 동일한 이름의 블럭이 이미 추가되어 있는 경우 목록에서 제거
          // 중복 추가 막기 위함
          let pageBlocks = this.device === 'pc' ? this.page.pageBlocksPc : this.page.pageBlocks;
          pageBlocks = pageBlocks.filter(block => {
            return block.name === this.selectedBlock.name && block.template_name !== this.selectedBlock.template_name
          }).map(block => {
            return block.template_name
          });

          this.blocks = res.data.filter(data => {
            return pageBlocks.indexOf(data.template_name) === -1
          });

          let index = this.blocks.findIndex(b => { return b.id === this.selectedBlock.id });
          if(index>-1) {
            let sel = this.cloneItem(this.blocks[index]);
            this.blocks.remove(index);
            this.blocks.splice(0,0,sel);
          }

          this.blockName = this.blocks[0].kor_name;
          this.setMasonry();
        });
      },
      setMasonry() {
        this.vBoxMobile = false;
        setTimeout(()=>{
          this.$nextTick(()=>{
            // eslint-disable-next-line no-undef
            new Masonry(document.getElementsByClassName('builder-box-mobile')[0],
              { itemSelector: ".block-template-mobile", percentPosition:true})
          })
          this.vBoxMobile = true;
          }, 500)
      },
      clickPageBlock(block) {
        if(this.excludeBlock.indexOf(block.name) === -1) {
          this.selectedBlock = block;
          this.getBlocks();
        }
      },
      clear() {
        this.selectedBlock = {id: 0};
        this.blockName = '';
        this.blocks = [];
      },
      clickBlock(block) {
        this.selectedBlock.preview = block.preview;
        this.selectedBlock.template_name = block.template_name;
        this.selectedBlock.id = block.id;
      },
      hover(block) {
        if(this.excludeBlock.indexOf(block.name)===-1) {
          block.hover = true;
        }
      }
    },
    watch: {
      page() {
        this.clear();
        this.getData();
      },
      device() {
        this.clear();
        this.getData();
      }
    },
    computed: {
      computedPageBlocks() {
        return this.device === 'pc' ? this.page.pageBlocksPc : this.page.pageBlocks;
      },
      headerImg() {
        return this.device === 'pc' ?
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockHeaderPc.png' :
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockHeader.png';
      },
      footerImg() {
        return this.device === 'pc' ?
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/t1_FooterPc.png' :
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/t1_Footer.png';
      }
    }

  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .builder-container
    display flex
    height 100%
    background-color $gray3
    overflow hidden

  .preview-container
    flex 1
    height 100%
    position relative
    overflow-y auto

  .prop-container
    width 392px
    background-color white
    height 100%

  .mockup-wrapper
    padding-bottom 80px
    width 100%

  .mobile-mockup
  .pc-mockup
    background-color white
    box-shadow 0 4px 14px rgba(0, 0, 0, 0.15)
    border-radius 20px
    overflow hidden
    margin 0 auto

  .mobile-mockup
    width 400px

  .pc-mockup
    width calc(100% - 120px)
    max-width 1100px
    padding 20px

  .button
    height 36px
    font-size 14px
    flex 1

    img
      margin-right 8px
      filter invert(1)

  .button:first-child
    margin-right 6px

  .button:last-child
    margin-left 6px

  .selected-img
    filter invert(0) !important

  .inner
    height 100%
    border 1px solid #ddd
    overflow-y scroll
    padding 12px 12px 150px 12px
    line-height 2

  .builder-box
    height calc(100% - 132px)
    overflow-y auto
    padding 0 20px

  .builder-box-mobile-wrapper
    height calc(100% - 132px)
    overflow-y auto

  .builder-box-mobile
    position relative
    height 100%
    min-height 500px
    margin 0 auto
    overflow-y auto
    width calc(100% - 24px)


  .block-template-preview
  .block-template-preview-mobile
    border 2px solid #eeeeee

  .block-template-preview:hover
  .block-template-preview-mobile:hover
    box-shadow 0 2px 8px rgba(0, 0, 0, 0.15)

  .block-template-preview
    width 100%
    margin-bottom 16px

  .block-template-preview-mobile
    width 100%

  .block-template-mobile
    width 50%
    padding 4px 8px


  .block-template-preview-selected
    border 2px solid $primary
  .selector
    position absolute
    width 100%
    height 100%
    border 2px solid $primary
    display flex
    filter drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2))
  .selector-name
    background-color $primary
    color white
    padding 2px 8px
    height 28px
    font-size 12px
    position absolute
    top 0
    left 0
  .dim
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(36,36,40,0.5)
    text-align center
    display flex
    align-items center
    justify-content center
  .dim-content
    color white
  .dim-circle
    background-color white
    border-radius 100%
    width 72px
    height 72px
    display flex
    align-items center
    justify-content center
    margin 16px auto

  .box-hidden
    position fixed
    width 384px
    height 100%
    background-color white
    z-index 1
    right 0

</style>
